import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import baseURL from "./main.js"
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  token: localStorage.getItem('user-token') || '',
  status: '',
  loading: false,
  role: '',
  formUpload: false
}
const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  authRequest(state){
    state.status = 'loading'
  },
  startLoading(state){
    state.loading = true
  },
  stopLoading(state){
    state.loading = false
  },
  authSuccess(state, token){
    state.status = 'success'
    state.token = token
  },
  authError(state){
    state.status = 'error'
  },
  setRole(state, val){
    state.role = val
  },
  formState(state, val){
    state.formUpload = val
  }
}

const actions = {
  authLogin({commit}, user){
    return new Promise((resolve, reject)=>{
      commit('authRequest')
      axios({url: `${baseURL}/login`, data: user, method: 'POST'})
      .then(res=>{
        // console.log(res)
        if(res.data.data.email == 'superadmin@paddyglp.ng'){
          commit('setRole', 'superadmin')
          localStorage.setItem('user-role', 'superadmin')
          console.log('superadmin logged in')
        }else{
          commit('setRole', 'admin')
        }
        const token = `Bearer ${res.data.data.token}`
        //Store token in local storage
        localStorage.setItem('user-token', token)
        // Set token as deafult Authorization header for future requests
        axios.defaults.headers.common['Authorization'] = token
        commit('authSuccess', token)
        // dispatch(USER_REQUEST)
        resolve(res)
       })
      .catch(err =>  {
         commit('authError', err)
        //If auth fails, remove every possible user-token in local storage
        localStorage.removeItem('user-token')
        reject(err)
      })
    })
  },
  authLogout({commit, dispatch}){
    return new Promise((resolve, reject)=>{
      localStorage.removeItem('user-token')
      delete axios.defaults.headers.common['Authorization']
      // console.log('Logged out successfully')
      resolve()
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})