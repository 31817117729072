import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
// const Order = () => import('@/views/orders/Order')
// const OrderDetails = () => import('@/views/orders/OrderDetails')
// const Servicemen = () => import('@/views/servicemen/Servicemen')
// const SingleServiceman = () => import('@/views/servicemen/SingleServiceman')
const Agents = () => import('@/views/agents/Agents')
const ManageWallet = () => import('@/views/wallet/ManageWallet')
const Policies = () => import('@/views/policies/Policies')
const CancelledPolicies = () => import('@/views/policies/CancelledPolicies')
const BuyHealth = () => import('@/views/policies/BuyHealth')
const InactiveHealth = () => import('@/views/policies/InactiveHealth')
const Health = () => import('@/views/policies/Health')
const CancelledHealth = () => import('@/views/policies/CancelledHealth')
// const BuyDevice = () => import('@/views/policies/BuyDevice')
// const InactiveDevice = () => import('@/views/policies/InactiveDevice')
// const Device = () => import('@/views/policies/Device')
// const CancelledDevice = () => import('@/views/policies/CancelledDevice')
const BuyLife = () => import('@/views/policies/BuyLife')
const Life = () => import('@/views/policies/Life')
const InactiveLife = () => import('@/views/policies/InactiveLife')
const CancelledLife = () => import('@/views/policies/CancelledLife')
const Login = () => import('@/views/pages/Login')


Vue.use(Router)
const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: TheContainer,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'policies',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            name: 'Policies',
            path: '',
            component: Policies
          },
          {
            name: 'BuyHealth',
            path: '/policies/buyhealth',
            component: BuyHealth
          },
          {
            name: 'Health',
            path: '/policies/health',
            component: Health
          },
          {
            path: '/policies/inactivehealth',
            component: InactiveHealth
          },
          {
            name: 'CancelledHealth',
            path: '/policies/cancelledhealth',
            component: CancelledHealth
          },
          // {
          //   name: 'BuyDevice',
          //   path: '/policies/buydevice',
          //   component: BuyDevice
          // },
          // {
          //   name: 'Device',
          //   path: '/policies/device',
          //   component: Device
          // },
          // {
          //   path: '/policies/inactivedevice',
          //   component: InactiveDevice
          // },
          // {
          //   name: 'CancelledDevice',
          //   path: '/policies/cancelleddevice',
          //   component: CancelledDevice
          // },
          {
            name: 'BuyLife',
            path: '/policies/buylife',
            component: BuyLife
          },
          {
            name: 'Life',
            path: '/policies/life',
            component: Life
          },
          {
            path: '/policies/inactivelife',
            component: InactiveLife
          },
          {
            name: 'CancelledLife',
            path: '/policies/cancelledlife',
            component: CancelledLife
          },
          {
            name: 'cancelledpolicies',
            path: '/policies/cancelled',
            component: CancelledPolicies
          }
        ]
      },
      
      {
        path: 'enrollees',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            name: 'Enrollees',
            path: '',
            component: Agents
          },
        
        ]
      },
      {
        path: 'wallet',
        component: {render (c) { return c('router-view') }
        },
        children: [
          {
            name: 'managewallet',
            path: '',
            component: ManageWallet
          },
        ]
      },
    ]
  },
  {
    path: '/app/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    name: 'catchAll',
    component: Login
  }
]
const router =  new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes,
  
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('user-token')
  if (to.name !== 'Login' && !token) next({ name: 'Login' })
  else next()
})
export default router
